import * as React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import {Link} from 'gatsby';
import '../styles/main.less';

// markup
const NotFoundPage = () => {
  return (
      <div>
        <Helmet htmlAttributes={{lang: 'de'}}>
          <meta charSet="utf-8"/>
          <title>Kinderosteopathie Karin Eideloth</title>
        </Helmet>

        <Header/>

        <main className={'main main--error-404'}>

          <section className="section section--error-404" id={'error404'}>
            <h1 className={'heading heading--section'}>Seite nicht gefunden</h1>
            <h3 className={'heading'}>
              <Link href={'/'}>Bitte besuchen sie unsere Hauptseite</Link>
            </h3>
          </section>

        </main>

        <Footer></Footer>
      </div>
  );
};

export default NotFoundPage;
